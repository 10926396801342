<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Alarm</h2>
					</div>
				</div>
			</div>
		</div>
		<!-- tabs -->

		<!-- Table Container Card -->
		<b-card no-body class="mb-2">
			<b-tabs class="m-2" lazy>
				<b-tab active>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/candling.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Candling</span>
						</div>
					</template>
					<Candling-Alarm-List />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/vaccination-sch.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Vaccination</span>
						</div>
					</template>

					<Vaccination-Alarm-List />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/hatchability.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Hatching</span>
						</div>
					</template>
					<Hatching-Alarm-List />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/transfer.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Transfer</span>
						</div>
					</template>

					<Transfer-Alarm-List />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BTabs,
	BTab,
	BCardText,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";
import CandlingAlarmList from "./CandlingAlarm.vue";
import HatchingAlarmList from "./HatchingAlarm.vue";
import TransferAlarmList from "./TransferAlarm.vue";
import VaccinationAlarmList from "./VaccinationAlarm.vue";
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BFormDatepicker,
		vSelect,
		BCardText,
		BTabs,
		BTab,
		CandlingAlarmList,
		HatchingAlarmList,
		TransferAlarmList,
		VaccinationAlarmList,
		BImg,
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
