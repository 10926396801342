<template>
	<div>
		<div class="m-2">
			<!-- Table Top -->
			<b-row class="mb-2">
				<!-- Date picker -->
				<b-col cols="12" md="7">
					<b-row>
						<b-col md="5  ">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="2" class="d-flex align-items-end">
							<b-button @click="getAlarmList()" variant="success ">
								Filter
							</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<div v-if="alarmList && alarmList.total > 0">
				<b-table
					:items="alarmList.data"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ alarmList.from + data.index }}
						</div>
					</template>
					<template #cell(alarm_status)="data">
						<div v-if="data.value === 'Delayed'" class="delayed ml-2">
							Delayed
						</div>
						<div v-else></div>
					</template>

					<template #cell(alarm_type)="data">
						<div v-if="data.value === 'candling'" class="candling ml-2">
							Candling
						</div>
					</template>
				</b-table>
				<!-- source modal -->
				<b-modal id="add-source" title="Add Source" ok-only ok-title="Submit">
					<form ref="form" @submit.stop.prevent="handleSubmit">
						<b-form-group label="Enter Source Name">
							<div class="form-label-group">
								<b-form-input
									id="candlingName"
									placeholder="Enter Source Name"
								/>
							</div>
						</b-form-group>
					</form>
				</b-modal>
				<div class="mx-2 mb-2">
					<b-pagination
						:value="alarmList.current_page"
						:total-rows="alarmList.total"
						:per-page="alarmList.per_page"
						align="right"
						@change="getAlarmList"
					>
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img
					:src="
						require('@/assets/images/svg/infographics/candling_infographic.svg')
					"
					class="mr-2"
				/>
				<h4 class="mt-2 Secondary">No action found</h4>
			</div>
		</div>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BImg,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useSaleList from "./useAlarmList";
import saleStoreModules from "../alarmStoreModules";
import axiosIns from "@/libs/axios";
import moment from "moment";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BImg,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,
		vSelect,
		BFormDatepicker,
		BFormSelect,
	},
	data() {
		return {
			alarmList: [],
			perPage: 5,
			currentPage: 1,
			isSortDirDesc: null,
			searchQuery: null,
			sortBy: null,
			selected: null,
			selectedType: "candling",
			fields: [
				{ key: "index", label: "s.no" },
				{ key: "name", label: "name" },
				{ key: `from_day`, label: "from to" },
				{ key: `to_day`, label: "to day" },
				{ key: `alarm_status`, label: "alarm status" },
				{ key: `alarm_type`, label: "alarm type" },
				{
					key: "created_at",
					label: "date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
		};
	},

	methods: {
		getAlarmList(pageNo = 1) {
			const hatcheryId = this.$route.params.hatcheryId;
			let url = `web/hatchery/${hatcheryId}/get-alarm-list/${this.selectedType}`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.alarmList = response.data.alarmList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
	created() {
		this.hatcheryId = this.$route.params.hatcheryId;
		this.getAlarmList();
	},
	setup() {
		const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

		// Register module
		if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
			store.registerModule(INVOICE_APP_STORE_MODULE_NAME, saleStoreModules);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
				store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
		});

		const statusOptions = [
			"Downloaded",
			"Draft",
			"Paid",
			"Partial Payment",
			"Past Due",
		];

		const {
			fetchInvoices,
			AlarmTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,
			statusFilter,
			refetchData,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		} = useSaleList();

		return {
			fetchInvoices,
			AlarmTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,

			statusFilter,

			refetchData,

			statusOptions,

			avatarText,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}
.delayed {
	background-color: #7367f0;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 20px;
	border-radius: 10px;
	padding: 10px 10px;
}
.candling {
	background-color: #ff9f43;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 20px;
	border-radius: 10px;
	padding: 10px 10px;
}
.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
